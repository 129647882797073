import React from 'react';
import { Box, Container } from '@amzn/awsui-components-react';

interface DisplayContentInCodeProps {
  details: any;
}
const DisplayContentInCode: React.FC<DisplayContentInCodeProps> = (details: any) => {
  const STAGE: any = 'beta';
  return (
    <>
      {STAGE === 'dev' && (
        <Box padding={'m'}>
          <Container disableHeaderPaddings disableContentPaddings>
            <Box variant="code">
              <pre
                style={{
                  background: '#f6f8fa',
                  padding: '2rem',
                  overflowX: 'auto',
                  whiteSpace: 'pre-wrap',
                  wordWrap: 'break-word'
                }}
              >
                {JSON.stringify(details, null, 2)}
              </pre>
            </Box>
          </Container>
        </Box>
      )}
    </>
  );
};

export default DisplayContentInCode;
