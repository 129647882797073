/* istanbul ignore file */
import KatalLogger, { Level } from '@amzn/katal-logger';
import { UserAuthContext } from './components/context/AppContextModels';

interface ApiEventLog {
  requestType: 'custom_query' | 'query' | 'mutation';
  requestName: string;
  requestStatus: 'API_SUCCESS' | 'API_FAILED';
  responseTime: number;
}

export let logger: KatalLogger;

/**
 * @notice Do not change this structure unless communicated with OE team about this change.
 * Once both teams Aligned, then can change the structure of this.
 */
export const configureTheLogger = (userAuthData: UserAuthContext) => {
  const katalLoggerConfig = {
    url: 'https://beta.logger.fintech.device.finance.amazon.dev/v1/log',
    logThreshold: Level.INFO,
    maxLogLineSize: 10000,
    logToConsole: true,
    context: {
      app: 'DasFinSuite-website',
      env: 'beta',
      userAlias: userAuthData.Alias,
      displayName: userAuthData.DisplayName,
      givenName: userAuthData.GivenName,
      email: userAuthData.Email,
      userLDAPGroups: userAuthData.userLDAPGroups,
      isDev: userAuthData.isDev,
      isAdmin: userAuthData.isAdmin,
      isOEReadOnly: userAuthData.isOEReadOnly,
      authStatusMessage: userAuthData.auth_status_message,
      userAuthDataLoadingStatus: userAuthData.userAuthDataLoadingStatus
    }
  };
  logger = new KatalLogger(katalLoggerConfig);
  logger.addErrorListener();
};

/**
 * Logs API events to AWS CloudWatch and records them in AWS RUM, including failures.
 * @param apiEvent - The API event log.
 * @param error - Optional. The error object captured when an API request fails.
 */
export const logApiEvent = (apiEvent: ApiEventLog, error?: any) => {
  const logPayload = {
    logType: 'api',
    apiEvent: {
      operation: apiEvent.requestType,
      operationName: apiEvent.requestName,
      requestStatus: apiEvent.requestStatus,
      responseTime: apiEvent.responseTime,
      ...(error && { failureDetails: error })
    }
  };

  // Log to AWS CloudWatch using the logger
  if (error) {
    logger.error(`Request Name - ${apiEvent.requestName}, Response Time - ${apiEvent.responseTime}ms`, logPayload);
  } else {
    logger.info(`Request Name - ${apiEvent.requestName}, Response Time - ${apiEvent.responseTime}ms`, logPayload);
  }
};
