import { EnvironmentConfig, S3Config } from 'src/components/context/AppContextModels';

// Environment variables
export const ENVIRONMENT_VARIABLES = Object.freeze<{ env: EnvironmentConfig }>({
  env: {
    Region: 'us-west-2',
    Stage: 'beta',
    CloudFrontProtocol: 'https',
    CloudFrontDomain: 'd213bdca7d0zwn.cloudfront.net',
    CognitoUserPoolId: 'us-west-2_5gu9zPA1p',
    CognitoIdentityPoolId: 'us-west-2:c3557819-251d-4198-a1f6-3eb3fab8357b',
    AuthenticationType: 'AMAZON_COGNITO_USER_POOLS',
    CognitoUserPoolWebClientId: '7mul6rpov1bvn1b26tt4q9fble',
    CognitoDomain: 'das-finsuite-application-beta.auth.us-west-2.amazoncognito.com',
    ApplicationDomain: 'https://beta.fintech.device.finance.amazon.dev',
    AwsAccountId: '186446126748'
  }
});

// S3 configuration constants
export const S3_ENV_CONSTANTS = Object.freeze<{ ENVIRONMENT_VARIABLES: S3Config }>({
  ENVIRONMENT_VARIABLES: {
    Bucket: ENVIRONMENT_VARIABLES.env.Stage === 'dev' ? 'op-excellence-alpha' : `op-excellence-${ENVIRONMENT_VARIABLES.env.Stage}`,
    Prefix: 'oe-rpt-tables-data-export/'
  }
});
